/*global require, requirejs, define */
var require = {
	paths: {
		//Only include the modules that are used
		'bootstrap-collapse': 'Vendor/Bootstrap/collapse',
		'bootstrap-dropdown': "Vendor/Bootstrap/dropdown",
		'bootstrap-modal': 'Vendor/Bootstrap/modal',
		'bootstrap-transition': "Vendor/Bootstrap/transition",
		'bootstrap-tab': 'Vendor/Bootstrap/tab',
		'jquery': 'Vendor/jquery',
		'jquery-validate': 'Vendor/jquery.validate',
		'jquery-validate-configured': 'modules/jquery-validate-configured',
		'jquery-validation-unobtrusive': 'Vendor/jquery.validate.unobtrusive',
		'isMobile': 'Vendor/isMobile',
		//replace with swiper.js in the scripts-backup folder if you don't want jQuery dependency
		'swiper': 'Plugins/swiper.jquery',
		'search': 'Modules/search',
		'helpers': 'Modules/helpers',
		'facebook': '//connect.facebook.net/en_US/sdk',
		'selectric': 'Plugins/jquery.selectric',
		'bootstrap-tab-collapse': 'Plugins/bootstrap-tabcollapse',
		'cocoen': 'Plugins/cocoen-transpiled',
		'store-locator': 'Modules/store-locator',
		'text': 'Plugins/text',
		'product-finder': 'Modules/product-finder'
	},
	shim: {
		'bootstrap-transition': ['jquery'],
		'bootstrap-collapse': ['bootstrap-transition'],
		'bootstrap-dropdown': ['jquery'],
		'bootstrap-modal': ['jquery'],
		'bootstrap-tab': ['jquery'],
		'bootstrap-tab-collapse': ['jquery'],
		//remove if you are using the stand-alone swiper.js
		'swiper': ['jquery'],
		'facebook': {exports: 'FB'},
		'jquery-validate': ['jquery'],
		'jquery-validation-unobtrusive': ['jquery-validate'],
		'selectric': ['jquery']
	},
	map: {
		/***
		 * Any module that asks for 'jquery-validate' gets our 'jquery-validate-configured' module instead so we
		 * can set defaults for validation.
		 */
		'*': {
			'jquery-validate': 'jquery-validate-configured'
		},
		/***
		 * 'jquery-validate-configured' returns the real jquery validation.
		 */
		'jquery-validate-configured': {
			'jquery-validate': 'jquery-validate'
		}
	}
};

var attrMain = document.body.attributes['data-main'];

if (attrMain) {
	var jsMainVal = attrMain.value;
	var jsMainArr = jsMainVal.replace(/\.js/g, '').split(' ');
	require.deps = jsMainArr;
}

requirejs.config(require);

requirejs.onError = function (err) {
	'use strict';
	console.error(err.stack);
};

//add shared modules in the common dependencies
requirejs([
		'jquery',
		'isMobile',
		'helpers',
		'search',
		'product-finder',
		'bootstrap-collapse',
		'bootstrap-dropdown',
		'modules/navigation'],
	function ($, isMobile, helpers, Search) {
		'use strict';
		var init = function () {
			var search = new Search();
		};

		init();

	});

define("common", function(){});

