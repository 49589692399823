define('modules/navigation',[
	'jquery',
	'helpers'
], function ($, helpers) {
	"use strict";

	helpers.$document.ready(function () {

		var Settings;

		Settings = {
			$navbarCollapse: $('.js-navbar-collapse'),
			$navbarToggle: $('.js-navbar-toggle')
		};

		function mobileNavHandler() {
			Settings.$navbarCollapse.toggleClass('in');
			helpers.$body.toggleClass('mobile-nav-in noscroll');
		}

		function bind() {
			Settings.$navbarToggle.on('click', mobileNavHandler);
		}

		function init() {
			bind();
		}

		init();

	});

});

