define('helpers',['jquery'],
	function($) {
	'use strict';
	function Helpers() {

		var BREAKPOINT_SMALL = 768;

		var self = this;

		self.$body = $('body');
		self.$html = $('html');
		self.$document = $(document);
		self.$window = $(window);

		// Constants
		self.constants = {
			swiperInterval: 5000
		};

		//TODO: move to GA module
		self.trackUserEvent = function trackUserEvent(cat, action, label){
			console.log('tracking:', cat, action, label);

			if(typeof ga !== 'undefined') {
				/** GA v3.0 or GA universal
				* Documentation: https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide
				*/
				ga('send', 'event', cat, action, label);
			} else {
				console.warn('make sure GA is loaded before tracking events');
			}
		};

		self.debounce = function (func, wait, immediate) {
			var timeout;
			return function() {
				var context = this,
					args = arguments,
					callNow = immediate && !timeout,
					later = function() {
						timeout = null;
						if (!immediate) {
							func.apply(context, args);
						}
					};
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) {
					func.apply(context, args);
				}
			};
		};

		self.smallScreen = function(){
			return window.innerWidth < BREAKPOINT_SMALL;
		};

		self.isAndroidStock = function(){
			var ua = navigator.userAgent;
			return (ua.indexOf('Android') > -1 && ua.indexOf('Chrome') == -1);
		};

		self.getQueryStringValues = function (searchQuery) {
			var parts;

			location.queryString = {};
			// Split up query string by ? and & symbol
			searchQuery.substr(1).split("&").forEach(function (pair) {
				if (pair === "") {
					return
				}

				parts = pair.split("=");

				if (!location.queryString[parts[0]]) {
					location.queryString[parts[0]] = [];
				}
				// With newly split up query string create object, and decode any strings with URL encoding
				location.queryString[parts[0]].push(parts[1] && decodeURIComponent(parts[1].replace(/\+/g, " ")));

			});
			return location.queryString;
		}
	}

	return new Helpers();
});

