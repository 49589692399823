define('product-finder',[
	'jquery',
	'swiper',
	'text!Templates/product-finder-slide.html',
	'Plugins/Mustache',
	'helpers'
], function ($, Swiper, template, Mustache, helpers) {
	"use strict";

	/***
	 * Constructor for ProductFinder.
	 * @constructor
	 */
	function ProductFinder() {

		var currentQuestionId,
			currentQuestionNumber,
			getQuestionUrl,
			$prevQuestionButton,
			previousQuestionId,
			$productFinder,
			$productFinderQuestions,
			$question,
			$questionNumber,
			swiper,
			$swiperContainer;

		currentQuestionId = 0;
		currentQuestionNumber = 1;
		getQuestionUrl = '/umbraco/api/productselector/getquestion/';
		$productFinder = $('.product-finder');
		$prevQuestionButton = $productFinder.find('.product-finder--prev-question');
		$productFinderQuestions = $productFinder.find('.product-finder--questions');
		$question = $productFinderQuestions.find('.question');
		$questionNumber = $productFinderQuestions.find('.question-number');
		$swiperContainer = $productFinderQuestions.find('.swiper-container');

		if ($productFinder.length === 0) {
			return;
		}

		function bindEvents() {

			/***
			 * Handler for the previous question button
			 */
			$prevQuestionButton.on('click', function () {
				currentQuestionId = previousQuestionId;
				currentQuestionNumber--;

				if (currentQuestionNumber !== 1) {
					$prevQuestionButton.addClass('in');
				} else {
					$prevQuestionButton.removeClass('in');
				}

				getQuestion();
			});

			$swiperContainer.on('click', '.swiper-slide', function (e) {
				var $this;

				$this = $(this);
				currentQuestionId = $this.data('next-question-id');

				/***
				 * If no next question to go to, redirect to the recommendation page url for this answer,
				 * otherwise go to the next question.
				 */
				if (!currentQuestionId) {
					window.location = $this.data('recommendation-url');
				} else {
					currentQuestionNumber++;
					getQuestion();
				}

			});
		}

		/***
		 * Render out new set of answer slides and add to the swiper.
		 * @param response
		 */
		function buildSlides(response) {
			var slidesHtml = Mustache.render(template, response);
			swiper.prependSlide(slidesHtml);
		}

		function init() {
			initSwiper();

			if (swiper.container.length === 0) return;

			getQuestion();
			bindEvents();
		}

		/***
		 * Initialise our swiper. Set slides per view to 'auto' as we
		 * use CSS widths to determine how many slides to show.
		 */
		function initSwiper() {

			var startIndex = helpers.$window.width() < 768 ? 0 : 1;

			swiper = new Swiper('.product-finder--answers .swiper-container', {
				init: false,
				initialSlide: startIndex,
				nextButton: '.swiper-button-next',
				observer: true,
				prevButton: '.swiper-button-prev',
				slidesPerView: 'auto',
				spaceBetween: 5,
				breakpoints: {
					767: {
						slidesPerView: 1
					}
				}
			});

			swiper.init();
		}

		function getQuestion() {

			var params = {
				id: currentQuestionId
			};

			$productFinderQuestions.removeClass('in');

			$.get(getQuestionUrl, params)
				.success(function (response) {

					if (!$productFinder || $productFinder.length === 0) {
						/* If the product finder is not available on the page, skip.*/
						return;
					}

					swiper.removeAllSlides();

					// Inject new slides into .swiper-container using Mustache templating.
					buildSlides(response);

					// Update the question copy
					updateQuestion(response.questionText);

					// Fade back in
					$productFinderQuestions.addClass('in');

					previousQuestionId = response.previousQuestionId;

					// Always update the swiper after modifying slide content
					swiper.update();

				})
				.fail(function (data, textStatus, jqXHR) {
					console.log('Error: ', textStatus);
				});
		}

		/***
		 * Update the question number and copy.
		 * @param {string} questionText
		 */
		function updateQuestion(questionText) {
			$questionNumber.text(currentQuestionNumber + '.');
			$question.text(questionText);

			if (currentQuestionNumber !== 1) {
				$prevQuestionButton.addClass('in');
			}
		}

		init();

	}

	return new ProductFinder();

});

