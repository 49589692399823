/*global define */

define('search',["jquery"], function($) {
	"use strict";

	var $searchForm = $('.navigation-search--form'),
		$searchInput,
		isActive = false;

	/**
	 * Search component
	 * @constructor
	 */
	function Search() {
		if(!$searchForm.length){
			return;
		}

		$searchInput = $searchForm.find('.form-control');

		$('.js-navigation-search--button').on('click', toggleSearch);
	}

	/**
	 * Toggle search
	 * @param  {Event} e
	 */
	function toggleSearch(e) {
		e.preventDefault();
		$searchForm.toggleClass('is-search-active');
		isActive = !isActive;

		if($searchInput.val() === '' && isActive){
			setTimeout(function(){
				$searchInput.focus();
			}, 300);
		}
	}

	return Search;
});

